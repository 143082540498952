import { documentReady } from "./utils/dom"

import "./styles-2020/main.scss"
import "./password"
import tippy from "tippy.js"
import { getSlider } from "./vendor/simpleslider.js"

documentReady(() => {
  const sliderElement = document.querySelector(".slider")

  if (sliderElement) {
    const slider = getSlider({ duration: 1, delay: 6 })
    slider.reverse()
  }

  tippy(".help-text", {
    allowHTML: true,
    hideOnClick: "toggle",
    placement: "auto",
    trigger: "focusin",
  })

  tippy("#register_form h2", {
    content() {
      const id = document.querySelector(".help-text-clicked").getAttribute("data-template")
      const template = document.getElementById(id)
      return template.innerHTML
    },
    triggerTarget: document.querySelector(".help-text-clicked"),
    allowHTML: true,
    trigger: "click",
    placement: "right",
  })
})
