import { documentReady } from "./utils/dom"

const revealPasswordTimeouts = []

function revealPassword(el) {
  const inputField = el.querySelector("input")
  const fieldId = inputField.getAttribute("id")

  inputField.type = "text"
  clearTimeout(revealPasswordTimeouts[fieldId])
  revealPasswordTimeouts[fieldId] = setTimeout(() => {
    inputField.type = "password"
  }, 3000)
}

documentReady(() => {
  const containerElements = document.querySelectorAll(".password-with-reveal")

  containerElements.forEach((containerElement) => {
    const iconElement = containerElement.querySelector("i")
    if (iconElement) {
      iconElement.addEventListener("click", () => revealPassword(containerElement))
    }
  })
})
